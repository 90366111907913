var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Modal",
    {
      attrs: {
        size: "normal",
        visible: _vm.visible,
        "mask-closable": false,
        closable: "",
        "z-index": 9,
        title: "修改计薪周期",
      },
      on: {
        ok: _vm.submitEdit,
        cancel: function ($event) {
          _vm.visible = false
        },
      },
    },
    [
      _c("Alert", {
        attrs: { "show-icon": "", type: "warning" },
        scopedSlots: _vm._u([
          {
            key: "content",
            fn: function () {
              return [
                _c("p", { staticClass: "alert-p" }, [
                  _vm._v(
                    "1.修改后可能出现薪酬信息丢失、系统异常、历史薪酬月报表内计薪周期显示错误等多种问题,请谨慎操作,请您先"
                  ),
                  _c("span", { staticClass: "article-common" }, [
                    _vm._v("线下备份好数据"),
                  ]),
                  _vm._v(",确定后再行修改。"),
                ]),
                _c("p", { staticClass: "alert-p" }, [
                  _vm._v("2.修改后将无法倒退回历史计薪月,且"),
                  _c("span", { staticClass: "article-common" }, [
                    _vm._v("系统将自动调整当前计薪月的开始、结束时间"),
                  ]),
                  _vm._v(
                    ",这会影响到您的考勤和计薪统计，修改当月甚至可能需要贵公司线下计薪。"
                  ),
                ]),
                _c("p", { staticClass: "alert-p" }, [
                  _vm._v("3.更改周期后"),
                  _c("span", { staticClass: "article-common" }, [
                    _vm._v("生效年月计薪规则"),
                  ]),
                  _vm._v(
                    "：生效月已计薪日期将从当月计薪天数中扣除、或当月未参与计薪天数自动合并至生效月计薪。"
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _c(
        "a-form-model",
        {
          ref: "ruleForm",
          attrs: {
            rules: _vm.rules,
            model: _vm.form,
            "label-col": _vm.labelCol,
            "wrapper-col": _vm.wrapperCol,
          },
        },
        [
          _c(
            "a-form-model-item",
            { attrs: { label: "计薪周期", prop: "startDay" } },
            [
              _c(
                "div",
                { staticClass: "item-content" },
                [
                  _c(
                    "a-select",
                    {
                      staticClass: "date-sum",
                      model: {
                        value: _vm.form.startMonthType,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "startMonthType", $$v)
                        },
                        expression: "form.startMonthType",
                      },
                    },
                    _vm._l(_vm.timeList, function (item) {
                      return _c(
                        "a-select-option",
                        {
                          key: "timeList" + item.codeKey,
                          attrs: { value: item.codeKey },
                        },
                        [_vm._v(" " + _vm._s(item.content) + " ")]
                      )
                    }),
                    1
                  ),
                  _c(
                    "a-select",
                    {
                      staticClass: "date-sum",
                      model: {
                        value: _vm.form.startDay,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "startDay", $$v)
                        },
                        expression: "form.startDay",
                      },
                    },
                    _vm._l(28, function (item, index) {
                      return _c(
                        "a-select-option",
                        { key: "date-sum" + index, attrs: { value: item } },
                        [_vm._v(" " + _vm._s(item) + "日 ")]
                      )
                    }),
                    1
                  ),
                  _vm._v(" " + _vm._s(_vm.dateText) + " "),
                ],
                1
              ),
            ]
          ),
          _c(
            "a-form-model-item",
            { attrs: { label: "生效月份", prop: "effectiveYearMonth" } },
            [
              _c("a-month-picker", {
                attrs: {
                  "disabled-date": _vm.disabledMonth,
                  "value-format": "YYYYMM",
                  format: "YYYY-MM",
                },
                model: {
                  value: _vm.form.effectiveYearMonth,
                  callback: function ($$v) {
                    _vm.$set(_vm.form, "effectiveYearMonth", $$v)
                  },
                  expression: "form.effectiveYearMonth",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "agreeText" },
        [
          _c(
            "a-checkbox",
            {
              staticClass: "agreement",
              model: {
                value: _vm.isDetermine,
                callback: function ($$v) {
                  _vm.isDetermine = $$v
                },
                expression: "isDetermine",
              },
            },
            [_vm._v("我已清楚并愿意承担修改计薪周期带来的风险")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }